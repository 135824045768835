import React from "react";
import type { ReactNode } from "react";
import { PrimaryContainer } from "../../../../Shared/Styles/PrimaryStylesGroup/Container";
import { Typography, Row } from "antd";
import { AdvantagesData } from "../../../../Shared/Localization/IntellessiPage/advantages";
import { CSSAdvantageHoverBlock } from "./styles";

import { ReactComponent as OIcon } from "../../../../Shared/Assets/images/intellessi/advantages/icon_1.svg";
import { ReactComponent as TIcon } from "../../../../Shared/Assets/images/intellessi/advantages/icon_2.svg";
import { ReactComponent as ThIcon } from "../../../../Shared/Assets/images/intellessi/advantages/icon_3.svg";
import { ReactComponent as FIcon } from "../../../../Shared/Assets/images/intellessi/advantages/icon_4.svg";
import { size } from "../../../../Shared/consts/MediaQueries";

const { Title, Paragraph } = Typography;

export const Advantages: React.FC = () => {
  const icons: Record<string, ReactNode> = {
    icon_1: <OIcon />,
    icon_2: <TIcon />,
    icon_3: <ThIcon />,
    icon_4: <FIcon />,
  };

  return (
    <div style={{ backgroundColor: "#13087A" }}>
      <PrimaryContainer $margin={40}>
        <Row style={{ padding: "50px 0px" }}>
          <div>
            <Title
              level={3}
              style={{
                color: "#FFFFFF",
                fontSize: size.mobileL >= window.innerWidth ? "35px" : "42px",
                margin: "0px",
              }}
            >
              {AdvantagesData.title}
            </Title>

            <Title
              level={3}
              style={{
                color: "#FFFFFF",
                margin: "0px",
                fontSize: size.mobileL >= window.innerWidth ? "35px" : "42px",
                lineHeight: "40px",
              }}
            >
              {AdvantagesData.subTitle}
            </Title>
          </div>

          <Row justify={"center"}>
            {AdvantagesData.list.map(
              (
                item: { title: string; text: string; icon: string },
                index: number,
              ) => (
                <CSSAdvantageHoverBlock key={index}>
                  <div style={{ width: "60px", height: "auto" }}>
                    {icons[item.icon]}
                  </div>

                  <Title
                    level={5}
                    style={{ margin: "15px 0px 5px", fontSize: "18px" }}
                  >
                    {item.title}
                  </Title>
                  <Paragraph style={{ margin: "0px", fontSize: "16px" }}>
                    {item.text}
                  </Paragraph>
                </CSSAdvantageHoverBlock>
              ),
            )}
          </Row>
        </Row>
      </PrimaryContainer>
    </div>
  );
};
