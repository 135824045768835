import SabpekLA from "Shared/Assets/uploads/САБПЭК.Лицензионный_договор.pdf";
import SabpekUM from "Shared/Assets/uploads/САБПЭК.Инструкция_пользователя.pdf";
import SabpekFD from "Shared/Assets/uploads/САБПЭК.Функциональное_описание.pdf";
import SabpekDI from "Shared/Assets/uploads/САБПЭК.Инструкция_по_развертыванию.pdf";

import DiasLA from "Shared/Assets/uploads/ЦИАС.Лицензионный_договор.pdf";
import DiasUM from "Shared/Assets/uploads/ЦИАС.Инструкция_пользователя.pdf";
import DiasDI from "Shared/Assets/uploads/ЦИАС.Инструкция_по_установке.pdf";

import IntellessiUM from "Shared/Assets/uploads/Интеллесси.Инструкция_пользователя.pdf";
import IntellessiFD from "Shared/Assets/uploads/Интеллесси.Функциональное_описание.pdf";
import IntellessiDI from "Shared/Assets/uploads/Интеллесси.Развёртывание_виртуальной_машины_с_ПО_Интеллесси.pdf";

export const iconDocs = (type: string) => {
  switch (type) {
    case "SabpekLA":
      return SabpekLA;
    case "SabpekUM":
      return SabpekUM;
    case "SabpekFD":
      return SabpekFD;
    case "SabpekDI":
      return SabpekDI;
    case "DiasLA":
      return DiasLA;
    case "DiasUM":
      return DiasUM;
    case "DiasDI":
      return DiasDI;
    case "IntellessiUM":
      return IntellessiUM;
    case "IntellessiFD":
      return IntellessiFD;
    case "IntellessiDI":
      return IntellessiDI;
    default:
      return "Error!";
  }
};
